import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["phoneInput", "phoneNumberInput"];

  connect() {
    this.initializeTelInput();
  }

  initializeTelInput() {
    const input = this.phoneInputTarget;
    const iti = window.intlTelInput(input, {
      initialCountry: "br",
      formatOnDisplay: true,
      autoHideDialCode: true,
      separateDialCode: true,
      nationalMode: true,
      preferredCountries: ["br", "us", "pt"],
      localizedCountries: { "pt-BR": "Portugues" },
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });

    input.addEventListener('change', () => {
      if (iti.isValidNumber()) {
        input.classList.remove('is-invalid');
        this.phoneNumberInputTarget.value = iti.getNumber();
      } else {
        input.classList.add('is-invalid');
        this.phoneNumberInputTarget.value = "";
      }
    });
  }
}

