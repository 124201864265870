import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js";

// Connects to data-controller="offers--bump-form"
export default class extends Controller {
  
  static targets = [ 
    'productInput',
    'offerInput',
    'nameInput',
    'bodyInput',
    'bodyText',
    'titleCardHeader',
    'titleHeaderTitle',
    'bodyCard',
    'nameLabel',
    'fromAmountLabel',
    'amountLabel',
    'bumpOfferContainer'
  ];

  static values = { 
    fetchOffersUrl: String
  };

  connect = () => {
    const options = {
      searchEnabled: true,
      itemSelectText: '',
      shouldSort: true,
      position: 'bottom',
      removeItemButton: true,
      placeholder: true
    };

    // initialize the choices dropdown for the product
    this.productChoices = new Choices(this.productInputTarget, options);
    // initialize the choices dropdown for the offers
    this.offerChoices = new Choices(this.offerInputTarget, options);

    this.offers = [];
  }

  onProductChange = (event) => {
    let productId = event.target.value;
    let product = this.productChoices.getValue();
    
    fetch(`${this.fetchOffersUrlValue}/products/${productId}/offers?for_bump=1`, { headers: { accept: 'application/json'} })
      .then(response => response.json())
      .then(data => {
        this.offers = data;
        this.offerChoices.removeActiveItems();
        this.offerChoices.setChoices(data, 'id', 'name', true);
      });
  }

  onOfferChange = (event) => {
    let offerId = event.target.value;
    let offer = this.offers.find(offer => offer.id == offerId);
    this.bumpOfferContainerTarget.hidden = false;
    
    this.nameInputTarget.value = offer.name;
    this.nameLabelTarget.innerHTML = offer.name;
    this.amountLabelTarget.innerHTML = offer.display_charge_amount;
  }

  onTitleChange = (event) => {
    this.titleHeaderTitleTarget.innerHTML = event.target.value;
  }

  onBodyChange = (event) => {
    this.bodyTextTarget.innerHTML = event.target.value;
  }

  onTitleColorChange = (event) => {
    this.titleCardHeaderTarget.style.color = event.target.value;
  }

  onTitleBgColorChange = (event) => {
    this.titleCardHeaderTarget.style.backgroundColor = event.target.value;
    this.bodyCardTarget.style.borderColor = event.target.value;
  }

  onNameChange = (event) => {
    this.nameLabelTarget.innerHTML = event.target.value;
  }

  onAmountChange = (event) => {
    this.fromAmountLabelTarget.innerHTML = event.target.value;
  }
}
