import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout-sessions--form"
export default class extends Controller {
  
  static targets = [ 
    'bumpOfferCheckbox',
    'bumpOfferCard',
    'installmentsRow',
    'installmentsSpinner',
    'installmentsInput',
    'priceHighlight',
    'priceSubtitle',
    'paymentBreakdownContainer',
    'creditCardContainer',
    'bankSlipContainer',
    'pixContainer',
    'submitButton'
  ];

  onBumpOfferChange = (e) => {
    if (e.target.checked) {
      e.target.closest('.bump-offer').classList.add('active');
    } else {
      e.target.closest('.bump-offer').classList.remove('active');
    }

    this.calculatePaymentAndRebuildElements();
  }

  onInstallmentsChanged = (e) => {
    this.calculatePaymentAndRebuildElements();
  }

  onPaymentMethodChanged = (e) => {
    let paymentMethod = e.target.value;
    // set the hidden input value to the selected payment
    document.getElementById('checkout_session_payment_method').value = paymentMethod;

    this.calculatePaymentAndRebuildElements();
    this.updatePaymentMethodVisibility(e.target.value);

    this.setSubmitButtonValue(paymentMethod)
  }

  updatePaymentMethodVisibility = (paymentMethod) => {
    if (this.hasCreditCardContainerTarget) {
      this.creditCardContainerTarget.hidden = paymentMethod !== 'credit_card';
    }
    
    if (this.hasBankSlipContainerTarget) {
      this.bankSlipContainerTarget.hidden = paymentMethod !== 'boleto';
    }
    
    if (this.hasPixContainerTarget) {
      this.pixContainerTarget.hidden = paymentMethod !== 'pix';
    }
  }

  calculatePaymentAndRebuildElements = () => {
    if (document.getElementById('checkout_session_pricing_type').value == 'recurring') { return; }
    
    this.installmentsRowTarget.hidden = true;
    this.installmentsSpinnerTarget.hidden = false;

    let bumpOfferNodes = document.querySelectorAll('input[name="checkout_session[bump_offers][]"]:checked');
    let bumpOfferIds = Array.from(bumpOfferNodes).map(checkbox => checkbox.value);

    fetch('/checkout_sessions/calculate_payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        offer_id: document.getElementById('checkout_session_offer_id').value,
        installments: document.getElementById('checkout_session_card_installments').value,
        payment_method: document.getElementById('checkout_session_payment_method').value,
        bump_offer_ids: bumpOfferIds
      })
    }).then(response => response.json())
      .then(data => {
        this.installmentsSpinnerTarget.hidden = true;
        this.installmentsRowTarget.hidden = false;
        
        document.getElementById('checkout_session_card_installments').innerHTML = data.installments_html;
        this.priceHighlightTarget.innerHTML = data.price_highlight;
        this.priceSubtitleTarget.innerHTML = data.price_subtitle;

        this.paymentBreakdownContainerTarget.innerHTML = data.payment_breakdown;
      });
  }

  setSubmitButtonValue = (paymentMethod) => {
    if (paymentMethod === 'boleto') {
      this.submitButtonTarget.value = 'Gerar Boleto';
    } else if (paymentMethod === 'pix') {
      this.submitButtonTarget.value = 'Gerar PIX';
    } else {
      this.submitButtonTarget.value = 'Comprar Agora';
    }
  }
}
