import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vitrines--form"
export default class extends Controller {
  
  static targets = [ 
    'isPublicInput',
    'restrictedRow'
  ];

  onIsPublicChange = (event) => {
    this.restrictedRowTarget.hidden = event.target.checked;
  }
}
