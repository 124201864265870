import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="classrooms--deliveries-form"
export default class extends Controller {
  
  static targets = [
    'accessTypeInput',
    'accessValueInput',
    'onScheduleDateInput',
    'daysAfterPurchaseInput',
    'onScheduleDateRow',
    'daysAfterPurchaseRow'
  ];

  connect = () => {
    this.startEventListeners();
  }

  startEventListeners = () => {
    $(this.accessTypeInputTarget).on('select2:select', function () {
      this.dispatchEvent(new Event('change', { bubbles: true })); // fire a native event
    });
  }

  onAccessTypeChange = (event) => {
    let selectedAccessType = event.target.value;
    let isOnSchedule = selectedAccessType == 'on_schedule_date';
    let isDaysAfterPurchase = selectedAccessType == 'days_after_purchase';

    this.onScheduleDateRowTarget.hidden = !isOnSchedule;
    this.daysAfterPurchaseRowTarget.hidden = !isDaysAfterPurchase;
    
    // Clear the value when the access type changes
    this.accessValueInputTarget.value = '';
  }

  setAccessValue = (event) => {
    this.accessValueInputTarget.value = this.accessTypeInputTarget.value == 'on_schedule_date' ? moment(event.target.value, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss -0300") :  event.target.value;
  }

  onFormSuccess = (event) => {
    Swal.fire({type: 'success', title: '', text: event.detail[0].message, showConfirmButton: false, timer: 1500 }).then((result) => {
      Turbo.visit(location.toString());
    });
  }
}
