import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="events--form"
export default class extends Controller {
  
  static targets = [ 
    'visibilityInput',
    'restrictedRow'
  ];

  connect = () => {
    $(this.visibilityInputTarget).on('select2:select', function () {
      this.dispatchEvent(new Event('change', { bubbles: true })); // fire a native event
    });
  }
  
  onVisibilityChange = (event) => {
    console.log('onVisibilityChange', event.target.value);
    this.restrictedRowTarget.hidden = event.target.value === 'is_public';
  }
}
