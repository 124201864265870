import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="orders--reject"
export default class extends Controller {

  static values = {
    url: String
  }

  connect = () => {
    this.reject();
  }

  reject = () => {
    Swal.fire({
      type: 'info',
      title: 'Quase lá!',
      html: 'Aguarde enquanto finalizamos o processamento ...</br></br>',
      allowOutsideClick: false
    })

    Swal.showLoading();

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({})
    }).then(response => response.json())
      .then(data => {
        if (data.success) {
          this.onRejectSuccess(data);
        } else {
          this.onRejectUnsuccess(data);
        }
      });
  }

  onRejectSuccess = (response) => {
    Swal.fire({type: 'success', title: '', text: response.message, showConfirmButton: false, timer: 1500 }).then((result) => {
      if (response.data.redirect_to_url != undefined && response.data.redirect_to_url != '') {
        Turbo.visit(response.data.redirect_to_url);
      } else {
        Turbo.visit(location.toString());
      }
    });
  }

  onRejectUnsuccess = (response) => {
    Swal.fire({type: 'error', title: '', text: response.message, showConfirmButton: false, timer: 1500 }).then((result) => {
      Turbo.visit(location.toString());
    });
    return;
  }
}
