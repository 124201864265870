import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="classrooms--deliveries-sidebar"
export default class extends Controller {
  
  static targets = ['sidebarCardBody'];

  connect = () => {
    window.addEventListener("classroomDeliverySaved", (event) => this.handleClassroomDeliverySaved(event));
  }

  handleClassroomDeliverySaved(event) {
    this.sidebarCardBodyTarget.innerHTML = event.detail[0].sidebar_html;
  }
}
