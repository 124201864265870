import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="orders--confirm"
export default class extends Controller {

  static values = {
    paymentMethod: String,
    confirmUrl: String
  }

  connect = () => {
    if (this.paymentMethodValue != "credit_card") {
      this.autoConfirm();
    }
  }

  autoConfirm = () => {
    Swal.fire({
      type: 'info',
      title: 'Quase lá!',
      html: 'Aguarde enquanto finalizamos o processamento da sua compra ...</br></br>',
      allowOutsideClick: false
    })

    Swal.showLoading();

    fetch(this.confirmUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        installments: 1
      })
    }).then(response => response.json())
      .then(data => {
        if (data.success) {
          this.onConfirmSuccess(data);
        } else {
          this.onConfirmUnsuccess(data);
        }
      });
  }

  onFormSuccess = (event) => {
    let response = event.detail[0];
    
    if (response == undefined) {
      Swal.fire({type: 'error', title: '', text: 'Não foi possível confirmar seu pedido!', showConfirmButton: true }).then((result) => {
        Turbo.visit(location.toString());
      });
      return;
    }

    if (response.success) {
      this.onConfirmSuccess(response);
    } else {
      this.onConfirmUnsuccess(response);
    }
  }

  onFormError = (event) => {
    let response = event.detail[0];

    Swal.fire({type: 'error', title: '', text: response.message, showConfirmButton: true}).then((result) => {
      Turbo.visit(location.toString());
    });
  }

  onConfirmSuccess = (response) => {
    Swal.fire({type: 'success', title: '', text: response.message, showConfirmButton: false, timer: 1500 }).then((result) => {
      if (response.data.redirect_to_url != undefined && response.data.redirect_to_url != '') {
        Turbo.visit(response.data.redirect_to_url);
      } else {
        Turbo.visit(location.toString());
      }
    });
  }

  onConfirmUnsuccess = (response) => {
    Swal.fire({type: 'error', title: '', text: response.message, showConfirmButton: false, timer: 1500 }).then((result) => {
      Turbo.visit(location.toString());
    });
    return;
  }
}
