import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="plans--form"
export default class extends Controller {
  
  static targets = [ 
    'pricingTypeInput',
    'recurringTypeInput',
    'frequencyInput',
    'installmentsInput',
    'installmentsDefaultInput',
    'installmentsWithoutInterestInput',
    'trialingEnabledInput',
    'installmentsRow',
    'totalChargesRow',
    'recurringRow',
    'paymentSettingsRow',
    'trialingRow',
    'trialingDaysRow',
    'priceTierRow',
    'memberAreaRow',
    'extendedDaysRow',
    'daysOfAccessRow',
    'paymentSettingsCard',
    'memberAreaCard',
    'inAppPurchaseAlert'
  ];

  static values = { 
    installmentOptions: [String],
    installmentDefaultOptions: [String]
  };

  connect = () => {
    this.startEventListeners();
    this.onPricingTypeChange({ target: { value: this.pricingTypeInputTarget.value } });
    this.onFrequencyChange({ target: { value: this.frequencyInputTarget.value } });
  }

  startEventListeners = () => {
    $(this.pricingTypeInputTarget).on('select2:select', function () {
      this.dispatchEvent(new Event('change', { bubbles: true })); // fire a native event
    });

    $(this.recurringTypeInputTarget).on('select2:select', function () {
      this.dispatchEvent(new Event('change', { bubbles: true })); // fire a native event
    });

    $(this.frequencyInputTarget).on('select2:select', function () {
      this.dispatchEvent(new Event('change', { bubbles: true })); // fire a native event
    });

    $(this.installmentsInputTarget).on('select2:select', function () {
      this.dispatchEvent(new Event('change', { bubbles: true })); // fire a native event
    });
  }

  onPricingTypeChange = (event) => {
    if (event.target.value == '') return;

    let selectedPricingType = event.target.value;
    let isRecurring = selectedPricingType == 'recurring' || selectedPricingType == 'recurring_with_installments';
    let isInstallments = selectedPricingType == 'installments' || selectedPricingType == 'recurring_with_installments';
    let isWeeklyOrMonthly = this.frequencyInputTarget.value == 'weekly' || this.frequencyInputTarget.value == 'monthly';
    
    this.recurringRowTarget.hidden = !isRecurring;
    this.installmentsRowTarget.hidden = !isInstallments || (isRecurring && isWeeklyOrMonthly);
    this.paymentSettingsCardTarget.hidden = selectedPricingType == 'cost_free';
    this.paymentSettingsRowTarget.hidden = event.target.value == 'cost_free' || event.target.value == 'in_app_purchase';
    this.trialingRowTarget.hidden = !isRecurring;
    this.priceTierRowTarget.hidden = event.target.value != 'in_app_purchase';
    this.memberAreaCardTarget.hidden = false;
    this.inAppPurchaseAlertTarget.hidden = event.target.value != 'in_app_purchase';
    this.extendedDaysRowTarget.hidden = !isRecurring;
    this.daysOfAccessRowTarget.hidden = isRecurring;
  }

  onRecurringTypeChange = (event) => {
    this.totalChargesRowTarget.hidden = event.target.value == 'charge_until_cancellation';
  }

  onFrequencyChange = (event) => {
    if (this.pricingTypeInputTarget.value == 'installments') return;
    
    this.installmentsRowTarget.hidden = (event.target.value == 'weekly' || event.target.value == 'monthly' || this.pricingTypeInputTarget.value == 'recurring');

    if (this.pricingTypeInputTarget.value == 'installments' || this.pricingTypeInputTarget.value == 'recurring_with_installments') {
      this.rebuildInstallmentsOptions(event.target.value);
    }
  }

  onTrialingEnabledChange = (event) => {
    this.trialingDaysRowTarget.hidden = !event.target.checked;
  }

  onInstallmentsChange = (event) => {
    // alert(event.target.value);
  }

  onMemberAreaEnabledChange = (event) => {
    this.memberAreaRowTarget.hidden = !event.target.checked;
  }

  setInstallmentValues = (frequency) => {
    let installmentValues = [];
    let installmentDefaultValues = [];

    if (frequency == 'bimonthly') {
      installmentValues = [['', ''], ["1 Parcela", "1"], ["2 Parcelas", "2"]];
      installmentDefaultValues = [['', ''], ["Parcela 1", "1"], ["Parcela 2", "2"]];
    } else if (frequency == 'quarterly') {
      installmentValues = [['', ''], ["1 Parcela", "1"], ["2 Parcelas", "2"], ["3 Parcelas", "3"]];
      installmentDefaultValues = [['', ''], ["Parcela 1", "1"], ["Parcela 2", "2"], ["Parcela 3", "3"]];
    } else if (frequency == 'semiannual') {
      installmentValues = [['', ''], ["1 Parcela", "1"], ["2 Parcelas", "2"], ["3 Parcelas", "3"], ["4 Parcelas", "4"], ["5 Parcelas", "5"], ["6 Parcelas", "6"]];
      installmentDefaultValues = [['', ''], ["Parcela 1", "1"], ["Parcela 2", "2"], ["Parcela 3", "3"], ["Parcela 4", "4"], ["Parcela 5", "5"], ["Parcela 6", "6"]];
    } else if (frequency == 'yearly' || this.pricingTypeInputTarget.value == 'installments') {
      installmentValues = [['', ''], ["1 Parcela", "1"], ["2 Parcelas", "2"], ["3 Parcelas", "3"], ["4 Parcelas", "4"], ["5 Parcelas", "5"], ["6 Parcelas", "6"], ["7 Parcelas", "7"], ["8 Parcelas", "8"], ["9 Parcelas", "9"], ["10 Parcelas", "10"], ["11 Parcelas", "11"], ["12 Parcelas", "12"]];
      installmentDefaultValues = [['', ''], ["Parcela 1", "1"], ["Parcela 2", "2"], ["Parcela 3", "3"], ["Parcela 4", "4"], ["Parcela 5", "5"], ["Parcela 6", "6"], ["Parcela 7", "7"], ["Parcela 8", "8"], ["Parcela 9", "9"], ["Parcela 10", "10"], ["Parcela 11", "11"], ["Parcela 12", "12"]];
    }

    this.installmentOptionsValue = installmentValues;
    this.installmentDefaultOptionsValue = installmentDefaultValues;
  }

  getHtmlOptions = (options, selectedOption) => {
    return options.map(option => {
      let selected = option[1] === selectedOption ? 'selected' : '';
      return `<option value="${option[1]}" ${selected}>${option[0]}</option>`;
    }).join('');
  }

  rebuildInstallmentsOptions = (frequency) => {
    this.setInstallmentValues(frequency);
    this.installmentsInputTarget.innerHTML = this.getHtmlOptions(this.installmentOptionsValue, this.installmentsInputTarget.value);
    this.installmentsDefaultInputTarget.innerHTML = this.getHtmlOptions(this.installmentDefaultOptionsValue, this.installmentsDefaultInputTarget.value);
    this.installmentsWithoutInterestInputTarget.innerHTML = this.getHtmlOptions(this.installmentOptionsValue, this.installmentsWithoutInterestInputTarget.value);
  }
}
