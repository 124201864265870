import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subscriptions--show"
export default class extends Controller {

  static values = { locale: Object }

  onConfirmRenewalClicked = (event) => {
    event.preventDefault();

    let confirmLocale = this.localeValue.actions.confirm_renewal;
    this.actionConfirmation(confirmLocale.confirm_title, confirmLocale.title, confirmLocale.btn_cancel_title,
      confirmLocale.btn_confirm_title, confirmLocale.error_title, event.target.href)
  }

  onCancelRenewalClicked = (event) => {
    event.preventDefault()

    let cancelLocale = this.localeValue.actions.cancel_renewal;
    this.actionConfirmation(cancelLocale.confirm_title, cancelLocale.title, cancelLocale.btn_cancel_title,
      cancelLocale.btn_confirm_title, cancelLocale.error_title, event.target.href)
  }

  actionConfirmation = (title, text, cancelButtonText, confirmButtonText, fetchErrorText, fetchUrl) => {
    Swal.fire({
      title: title,
      text: text,
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: applicationDataLayer.account.primaryColor,
      cancelButtonColor: applicationDataLayer.account.secondaryColor,
      cancelButtonText: cancelButtonText,
      confirmButtonText: "<i class='fa fa-check'></i>" + confirmButtonText
    }).then((result) => {
      if (result.value) {
        this.actionFetch(fetchUrl, fetchErrorText)
      }
    });
  };

  actionFetch = (url, errorText) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      
      return response.json()
    }).then(data => {
      this.actionFetchSuccess(data);
    }).catch((error) => {
      this.actionFetchError(error, errorText);
    });
  };

  actionFetchSuccess = (data) => {
    Swal.fire({type: 'success', title: '', text: data.message, showConfirmButton: false, timer: 3000 }).then((result) => {
      Turbo.visit(location.toString());
    });
  }

  actionFetchError = (error, errorText) => {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      confirmButtonColor: applicationDataLayer.account.primaryColor,
      text: errorText,
    }).then((result) => {
      if (result.value) {
        Turbo.visit(location.toString());
        return
      }
    });
  }
}