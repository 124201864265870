import { Controller } from "@hotwired/stimulus"
import consumer from "channels/consumer"

// Connects to data-controller="checkout-sessions--show"
export default class extends Controller {
  static values = { token: String }
  
  connect() {
    consumer.subscriptions.create({ channel: "CheckoutSessionsChannel", checkout_session_token: this.tokenValue } , {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (data.payment_status == 'paid') {
          Turbo.visit(location.toString());
        }
      }
    });
  }
}