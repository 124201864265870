import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="plans--form"
export default class extends Controller {
  
  static targets = [ 
    'roleTypeInput',
    'adminPermissionsRow',
    'adminMasterPermissionsRow',
    'selectAllCheckbox',
    'capabilityCheckbox'
  ];

  connect = () => {
    this.startEventListeners();
    this.onRoleTypeChange({ target: { value: this.roleTypeInputTarget.value } });
  }

  startEventListeners = () => {
    $(this.roleTypeInputTarget).on('select2:select', function () {
      this.dispatchEvent(new Event('change', { bubbles: true })); // fire a native event
    });
  }

  onRoleTypeChange = (event) => {
    let selectedRoleType = event.target.value;
    this.adminPermissionsRowTarget.hidden = selectedRoleType != 'admin';
    this.adminMasterPermissionsRowTarget.hidden = selectedRoleType != 'master_admin';

    if (selectedRoleType == 'master_admin') {
      this.capabilityCheckboxTargets.forEach(checkbox => checkbox.checked = false); // uncheck all checkboxes
    }
  }

  selectAllClicked = (event) => {
    let checked = event.target.checked;
    let checkboxValue = event.target.value;

    this.capabilityCheckboxTargets
      .filter(checkbox => checkbox.className.includes(checkboxValue))
      .forEach(checkbox => checkbox.checked = checked);
  }
}
