import { application } from "../controllers/application"

import ChoicesController from "./choices"
application.register("choices", ChoicesController)

import TelinputController from "./telinput"
application.register("telinput", TelinputController)

import MaskController from "./mask"
application.register("mask", MaskController)

import ModalController from "./modal"
application.register("modal", ModalController)

import TippyController from "./tippy"
application.register("tippy", TippyController)

import ClipboardController from "./clipboard"
application.register("clipboard", ClipboardController)