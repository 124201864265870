import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = [ "tippy" ];

  connect = () => {
    this.tippyTargets.forEach((buttonElement,index) => {
      tippy(buttonElement, {
        content: buttonElement.getAttribute('title'),
        animation: 'scale',
        allowHTML: true
      });
    });
  }
}